import { useCallback, useMemo } from 'react';
import { OnChange } from '../../edit/EditProps';
import { ResultCentralizerContext } from '../../../contexts/wysiwyg/ResultCentralizerContext';
import { ViewComponentProps } from '../../edit/wrapper/EditableWrapperProps';
import { EditableWrapperNumber } from '../../edit/wrapper/EditableWrapperNumber';
import { assertSafe } from '../../../../functions/src/util/assertSafe';
import { validateScore } from '../../../util/bracket/validateScore';
import { useCanScore } from '../../../hooks/tournaments/match-details/useCanScore';
import { memo } from '../../../util/memo';
import { ScoreDisplay } from './ScoreDisplay';

export const EDIT_CONFIG = {
  variant: 'outlined',
} as const;

export type SessionScoreCentralizedProps = {
  scoreIndex: number;
  isTwoTeams: boolean;
};
const SessionScoreUnmemoized = ({
  scoreIndex,
  isTwoTeams,
}: SessionScoreCentralizedProps) => {
  const { obj, updateObj } = ResultCentralizerContext.useEntireObject();
  const { scoresMatch = {}, stage, winThresholdStatus } = obj || {};
  const score = scoresMatch?.[assertSafe(scoreIndex)];
  const { units } = score || {};

  const deriveScoreOptions = useCallback(
    (value = 0) => {
      return { value, units } as const;
    },
    [units],
  );

  const gameScoreStatus = useMemo(() => {
    if (stage !== 'complete') {
      return;
    }
    if (winThresholdStatus === 'exceeding' || winThresholdStatus === 'bypass') {
      return 'winner';
    }
    return 'loser';
  }, [stage, winThresholdStatus]);

  const ViewScoreComponent = useCallback(
    ({ value }: ViewComponentProps<number>) => {
      return (
        <ScoreDisplay
          isTwoTeams={isTwoTeams}
          score={deriveScoreOptions(value)}
          status={gameScoreStatus}
        />
      );
    },
    [isTwoTeams, deriveScoreOptions, gameScoreStatus],
  );

  const canScore = useCanScore();

  const setScore: OnChange<number, number> = useCallback(
    (value?: number, valueOld?: number) => {
      if (value === undefined || value === valueOld) {
        return;
      }

      updateObj({
        [`scoresMatch.${scoreIndex}.value`]: value,
        stage: 'complete',
      });
    },
    [scoreIndex, updateObj],
  );

  return (
    <EditableWrapperNumber
      edit={EDIT_CONFIG}
      options={validateScore}
      ViewComponent={ViewScoreComponent}
      onChange={canScore ? setScore : 'disabled'}
    />
  );
};
export const SessionScoreCentralized = memo(SessionScoreUnmemoized);
