import { SxProps } from '@mui/material/styles';
import { useDeepCompareCallback } from '@blumintinc/use-deep-compare';
import {
  TextFieldEdit,
  TextFieldEditProps,
} from '../edit-component/TextFieldEdit';
import { compareDeeply, memo } from '../../../util/memo';
import { ValueChangeable } from '../EditProps';
import {
  EditableWrapperBase,
  EditableWrapperBaseProps,
} from './EditableWrapperBase';

export type EditableWrapperTextProps<
  TValue,
  TUrl extends string = string,
> = Omit<EditableWrapperBaseProps<TValue, string, TUrl>, 'EditComponent'> & {
  edit?: Omit<
    TextFieldEditProps<TValue>,
    keyof ValueChangeable<TValue, string>
  >;
};

function EditableWrapperTextUnmemoized<TValue, TUrl extends string = string>({
  edit,
  ...props
}: EditableWrapperTextProps<TValue, TUrl>) {
  const TextFieldEditMerged = useDeepCompareCallback(
    ({
      sx: instanceSx,
      viewSize,
      ...restInstance
    }: TextFieldEditProps<TValue>) => {
      const { sx: componentSx, ...restComponent } = edit ?? {};

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const mergedSx = {
        minWidth: '80px',
        ...instanceSx,
        ...componentSx,
        ...(viewSize?.width && { width: `${viewSize.width}px` }),
        // For height, apply specific styling to MUI TextField elements
        ...(viewSize?.height && {
          // Target the input container
          '& .MuiInputBase-root': {
            minHeight: `${viewSize.height}px`,
          },
          // Target the actual input for better text alignment
          '& .MuiOutlinedInput-input': {
            boxSizing: 'border-box',
            // Allow input to grow for multiline while keeping appropriate max height
            maxHeight: `${Math.max(viewSize.height - 16, 24)}px`,
          },
          // Ensure the outline border matches the dimensions
          '& .MuiOutlinedInput-notchedOutline': {
            boxSizing: 'border-box',
          },
        }),
      } as SxProps;

      return (
        <TextFieldEdit<TValue>
          sx={mergedSx}
          {...restInstance}
          {...restComponent}
        />
      );
    },
    [edit],
  );
  return (
    <EditableWrapperBase<TValue, string, TUrl>
      {...props}
      EditComponent={TextFieldEditMerged}
    />
  );
}

export const EditableWrapperText = memo(
  EditableWrapperTextUnmemoized,
  compareDeeply('link', 'edit'),
) as typeof EditableWrapperTextUnmemoized;
