import { GridColDef } from '@mui/x-data-grid';
import { useContext } from 'react';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { ResultRanked } from '../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';
import { useMatchDetails } from '../../contexts/MatchDetailsContext';
import { GameResultCell } from '../../components/tournaments/heats-leaderboards/GameResultCell';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import {
  SCORE_OPTION_OVERWOLF_GAMES,
  SCORE_OPTIONS_DEFAULT,
} from '../../../functions/src/util/in-game/scoreOptionLibrary';
import { toPlacementHeader } from '../../util/tournaments/toPlacementHeader';
import { toArr } from '../../../functions/src/util/toArr';
import { assertSafe } from 'functions/src/util/assertSafe';

export const useGameResultColumn = () => {
  const { overwolfGameId } = useContext(TournamentContext);
  const { match, matchDocPath } = useMatchDetails();
  const { resultsAggregation, scoreOptions } = match || {};
  const scoreOptionsAvailable = useDeepCompareMemo(() => {
    if (scoreOptions) {
      return scoreOptions;
    }
    if (overwolfGameId) {
      return SCORE_OPTION_OVERWOLF_GAMES[assertSafe(overwolfGameId)];
    }
    return SCORE_OPTIONS_DEFAULT;
  }, [overwolfGameId, scoreOptions]);
  const { teamCount = 0 } = resultsAggregation || {};

  const scoreOptionsOrdered = useDeepCompareMemo(() => {
    return toArr(scoreOptionsAvailable);
  }, [scoreOptionsAvailable]);

  const scoreColumns: GridColDef<ResultRanked>[] = useDeepCompareMemo(() => {
    return scoreOptionsOrdered.map(({ name }, index) => {
      const headerName = toPlacementHeader(name);
      return {
        field: name,
        headerName,
        minWidth: 130,
        maxWidth: 350,
        flex: 1,
        // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
        sortable: false,
        // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
        editable: false,
        renderCell: ({ row }) => {
          return (
            <GameResultCell
              matchDocPath={matchDocPath}
              result={row}
              scoreIndex={index}
              teamCount={teamCount}
            />
          );
        },
      } as const;
    });
  }, [scoreOptionsOrdered, matchDocPath, teamCount]);

  return scoreColumns;
};
