import { FC, useMemo } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';

export type ErrorValidationTooltipProps = Omit<TooltipProps, 'title'> & {
  /**
   * The error message to display in the tooltip.
   * If undefined or empty, the tooltip will not be displayed.
   */
  error?: string;
};

const ErrorValidationTooltipUnmemoized: FC<ErrorValidationTooltipProps> = ({
  error,
  children,
  open,
  placement = 'bottom',
  arrow = true,
  ...rest
}) => {
  const theme = useTheme();

  const slotProps = useMemo(() => {
    return {
      arrow: {
        sx: {
          color: `${theme.palette.error.light} !important`,
          '&::before': {
            borderColor: `${theme.palette.error.mid} !important`,
          },
        },
      },
      popper: {
        sx: {
          '& .MuiTooltip-tooltip': {
            padding: '0px',
            border: 'none',
          },
        },
      },
    } as const;
  }, [theme.palette.error.light, theme.palette.error.mid]);

  const shouldShow = !!error && open !== false;

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const title = useMemo(() => {
    return shouldShow ? (
      <Alert severity="error" variant="outlined">
        <AlertTitle>ERROR</AlertTitle>
        {error}
      </Alert>
    ) : undefined;
  }, [error, shouldShow]);

  return (
    <Tooltip
      arrow={arrow}
      open={!!title}
      placement={placement}
      slotProps={slotProps}
      title={title}
      {...rest}
    >
      {/* Wrap children in a span to ensure the tooltip has a valid anchor element */}
      <span style={{ display: 'inline-block', width: '100%' }}>{children}</span>
    </Tooltip>
  );
};

export const ErrorValidationTooltip = memo(ErrorValidationTooltipUnmemoized);
