import { useMemo, useCallback } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useDeepCompareCallback } from '@blumintinc/use-deep-compare';
import { memo } from '../../util/memo';
import { ViewComponentProps } from '../edit/wrapper/EditableWrapperProps';
import { EditProps } from '../edit/EditProps';
import { EditableWrapperText } from '../edit/wrapper/EditableWrapperText';
import { useAuth } from '../../contexts/AuthContext';
import { LivestreamCentralizerContext } from '../../contexts/wysiwyg/LivestreamCentralizerContext';

const EDIT_OPTIONS = {
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  multiline: false,
  variant: 'outlined',
} as const;

const OVERLAY_SX = {
  borderRadius: '8px',
  p: 1,
} as const;

export type LivestreamFieldEditableProps = Omit<TypographyProps, 'children'> & {
  value?: string;
  streamerId: string;
  fieldName: 'title' | 'description';
};

const LivestreamFieldEditableUnmemoized = ({
  value,
  streamerId,
  fieldName,
  ...typographyProps
}: LivestreamFieldEditableProps) => {
  const ViewField = useDeepCompareCallback(
    ({ value: contextValue }: ViewComponentProps<string>) => {
      return <Typography {...typographyProps}> {contextValue}</Typography>;
    },
    [typographyProps],
  );

  const EditableField = useCallback(
    (props: Omit<EditProps<string>, 'ViewComponent'>) => {
      return (
        <EditableWrapperText
          edit={EDIT_OPTIONS}
          overlaySx={OVERLAY_SX}
          ViewComponent={ViewField}
          {...props}
        />
      );
    },
    [ViewField],
  );

  const CentralizedField = useMemo(() => {
    return LivestreamCentralizerContext.centralize(EditableField, fieldName);
  }, [EditableField, fieldName]);

  const { uidFull } = useAuth();
  const canEdit = uidFull === streamerId;

  return (
    <CentralizedField
      value={value}
      onChange={canEdit ? undefined : 'disabled'}
    />
  );
};

export const LivestreamFieldEditable = memo(LivestreamFieldEditableUnmemoized);
