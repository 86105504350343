import {
  ScoreOption,
  ScoreOptions,
} from '../../types/firestore/Game/Tournament/Round/Match';
import {
  FORTNITE_GAME_ID,
  OverwolfGameId,
  ROCKET_LEAGUE_GAME_ID,
  VALORANT_GAME_ID,
} from '../../types/firestore/User/OverwolfGame';

export const DEFAULT_OUTCOME_NAME = 'Placement' as const;

export const OUTCOME_SCORE_OPTION: ScoreOption = {
  name: DEFAULT_OUTCOME_NAME,
  units: 'place',
  orderBy: 'asc',
};

export const SCORE_OPTIONS_DEFAULT: ScoreOptions = {
  '0': OUTCOME_SCORE_OPTION,
};

export const SCORE_OPTION_OVERWOLF_GAMES: Record<OverwolfGameId, ScoreOptions> =
  {
    [ROCKET_LEAGUE_GAME_ID]: { '0': OUTCOME_SCORE_OPTION },
    [VALORANT_GAME_ID]: { '0': OUTCOME_SCORE_OPTION },
    [FORTNITE_GAME_ID]: { '0': OUTCOME_SCORE_OPTION },
  };
//TODO: @shaffy9633 update OUTCOME_SCORE_OPTION for each game to the their primary outcome statistic.
//For the remaining options in the scoreOptions record, we can use the different non outcome statistics.
