import { DEFAULT_OUTCOME_NAME } from '../../../functions/src/util/in-game/scoreOptionLibrary';

export const PLACEMENT_HEADER = 'W/L' as const;

export const toPlacementHeader = (name: string) => {
  if (name.toLowerCase().includes(DEFAULT_OUTCOME_NAME.toLowerCase())) {
    return PLACEMENT_HEADER;
  }
  return name.toUpperCase();
};
