/* eslint-disable max-lines */
import {
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  ForwardedRef,
  useState,
  useRef,
} from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { EditProps, OnChange } from '../EditProps';
import { useTextInputDebounce } from '../../../hooks/useTextInputDebounce';
import { useErrorValidation } from '../../../hooks/edit/useErrorValidation';
import { memo } from '../../../util/memo';
import { ErrorValidationTooltip } from '../error/ErrorValidationTooltip';

export type TextFieldPropsRest = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'autoFocus' | 'error' | 'helperText'
>;

export type SelectPropsRest = Omit<
  SelectProps,
  'value' | 'onChange' | 'autoFocus'
>;

export type TextFieldEditProps<TValue> = EditProps<TValue, string> &
  Omit<TextFieldPropsRest & SelectPropsRest, keyof EditProps<TValue, string>>;

function TextFieldEditReflessUnmemoized<TValue>(
  {
    // eslint-disable-next-line no-restricted-syntax
    value = '' as TValue,
    onChange,
    options,
    onError,
    onKeyDown,
    ...rest
  }: TextFieldEditProps<TValue>,
  forwardedRef: ForwardedRef<HTMLDivElement>,
) {
  /**
   * We introduced debouncing because our validation functions can be slow and expensive async operations.
   * We don't want to invoke the validation function excessively.
   * Note: We only need debouncing for TextField mode, not for Select mode with predefined options.
   */
  const [valueUnvalidated, valueUnvalidatedDebounced, setValueUnvalidated] =
    useTextInputDebounce<string>(String(value));
  const pendingEnterEventRef = useRef<React.KeyboardEvent | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setValueUnvalidated(String(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Handle refs for error popover and forwarding
  const attachRef = useCallback(
    (node: HTMLDivElement | null) => {
      componentRef.current = node;

      if (typeof forwardedRef === 'function') {
        forwardedRef(node);
      } else if (forwardedRef) {
        forwardedRef.current = node;
      }
    },
    [forwardedRef],
  );

  const onChangeIntercepted = useMemo(() => {
    if (onChange === 'disabled') {
      return 'disabled';
    }
    const intercept: OnChange<TValue, string> = async (...args) => {
      const result = await onChange(...args);
      if (pendingEnterEventRef.current) {
        onKeyDown?.(pendingEnterEventRef.current);
        pendingEnterEventRef.current = null;
      }
      return result;
    };
    return intercept;
  }, [onChange, onKeyDown]);

  const {
    error: errorTextField,
    validateAndChange: validateAndChangeTextField,
  } = useErrorValidation({
    value,
    onChange: onChangeIntercepted,
    options,
    onError,
    setValueUnvalidated,
    valueUnvalidated: valueUnvalidatedDebounced,
  });

  const [isSelectOpen, setIsSelectOpen] = useState(true);

  const selectOptions = useMemo(() => {
    if (!Array.isArray(options)) {
      return null;
    }
    return options.map(String).map((option) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    });
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
  }, [options]);

  const changeSelect = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const newValue = e.target.value as string;
      setIsSelectOpen(false);

      // In Select mode, options are pre-validated, so we can directly call onChange
      if (onChangeIntercepted !== 'disabled') {
        onChangeIntercepted(newValue, value as TValue);
      }
    },
    [onChangeIntercepted, value, setIsSelectOpen],
  );

  const changeTextField = useCallback(
    (e: { target: { value: string } }) => {
      setValueUnvalidated(e.target.value);
    },
    [setValueUnvalidated],
  );

  const captureEnterTextField = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Enter') {
        onKeyDown?.(e);
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      pendingEnterEventRef.current = e;

      validateAndChangeTextField(valueUnvalidated);
    },
    [validateAndChangeTextField, valueUnvalidated, onKeyDown],
  );

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const select = useDeepCompareMemo(() => {
    if (!selectOptions) {
      return null;
    }
    const { sx = {}, ...selectProps } = rest as SelectPropsRest;
    return (
      <Select
        ref={attachRef}
        autoFocus
        fullWidth
        open={isSelectOpen}
        size="small"
        value={valueUnvalidated}
        onChange={changeSelect}
        onKeyDown={onKeyDown}
        // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns, @typescript-eslint/no-explicit-any
        {...(selectProps as any)}
        sx={sx}
      >
        {selectOptions}
      </Select>
    );
  }, [
    selectOptions,
    rest,
    attachRef,
    isSelectOpen,
    valueUnvalidated,
    changeSelect,
    onKeyDown,
  ]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const textField = useDeepCompareMemo(() => {
    const { sx = {}, ...textFieldProps } = rest as TextFieldPropsRest;
    return (
      <TextField
        ref={attachRef}
        autoFocus
        error={!!errorTextField}
        fullWidth
        multiline={false}
        size="small"
        value={valueUnvalidated}
        variant="outlined"
        onChange={changeTextField}
        onKeyDown={captureEnterTextField}
        {...textFieldProps}
        sx={sx}
      />
    );
  }, [
    rest,
    errorTextField,
    valueUnvalidated,
    changeTextField,
    captureEnterTextField,
    attachRef,
  ]);

  return (
    <ErrorValidationTooltip error={errorTextField}>
      {select || textField}
    </ErrorValidationTooltip>
  );
}

// eslint-disable-next-line @blumintinc/blumint/global-const-style
const TextFieldEditUnmemoized = forwardRef<
  HTMLDivElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TextFieldEditProps<any>
>(TextFieldEditReflessUnmemoized) as typeof TextFieldEditReflessUnmemoized;

export const TextFieldEdit = memo(
  TextFieldEditUnmemoized,
) as typeof TextFieldEditUnmemoized;
